module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"c90c7409-6d64-4d14-8226-d82627dcf997","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjBmOGUyN2RmLTRhYWUtNDU4OS1iNjVkLTYwMzQ0MGUzZmY2ZSIsInVzZXIiOiJjODJjNDg1NS1kNDVkLTQ1MDItOGQwOC0zM2EzNTliNGUzMTAiLCJzaXRlIjoiYzkwYzc0MDktNmQ2NC00ZDE0LTgyMjYtZDgyNjI3ZGNmOTk3IiwiaWF0IjoxNjQzNzIxNzk3fQ.Ae2DsDNhpyOnsTUAMGX6DUVg2CapZGTISXyNxudFVs4"},"prd":{"siteId":"c90c7409-6d64-4d14-8226-d82627dcf997","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjBmOGUyN2RmLTRhYWUtNDU4OS1iNjVkLTYwMzQ0MGUzZmY2ZSIsInVzZXIiOiJjODJjNDg1NS1kNDVkLTQ1MDItOGQwOC0zM2EzNTliNGUzMTAiLCJzaXRlIjoiYzkwYzc0MDktNmQ2NC00ZDE0LTgyMjYtZDgyNjI3ZGNmOTk3IiwiaWF0IjoxNjQzNzIxNzk3fQ.Ae2DsDNhpyOnsTUAMGX6DUVg2CapZGTISXyNxudFVs4"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src2588198383/src/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5QQVMT4","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NovaFarma","short_name":"novafarma","start_url":"/","background_color":"#e6e4e4","theme_color":"#1a3797","display":"minimal-ui","icon":"src/assets/images/favicon/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0ef5562fb4c76e2d264acded81b60018"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
